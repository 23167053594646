import CarouselImage from '@/assets/images/carouselImage.png';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
const Carousel = () => {
  const t = useTranslations('Home');
  const router = useRouter();
  const handleClickOrderNow = () => {
    router.push('/categories');
  };
  return <div className="carousel_wrapper" data-sentry-component="Carousel" data-sentry-source-file="Carousel.jsx">
            <div className="container">
                <div className="hero_title">
                    <h1>
                        <small> {t('the_chef_label')}</small>
                        {t('the_chef_label_description')}
                    </h1>
                    <span>{t('chef_pick_desc2')}</span>
                    <button onClick={handleClickOrderNow}>{t('order_now_button')}</button>
                </div>
                <div className="carouselImage">
                    <img src={CarouselImage.src} alt="carouselImage" className="carouselImg" />
                </div>
            </div>
        </div>;
};
export default Carousel;