import ArrowComponent from '@/components/SliderArrow';
import PopulardishSlider from '@/components/UI/PopulardishSlider';
import { useTranslations } from 'next-intl';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
const PopularDish = () => {
  const t = useTranslations('Home');
  const {
    home
  } = useSelector(store => store.common);
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedArrow, setSelectedArrow] = useState(null); // Track the selected arrow

  const handleArrowClick = direction => {
    setSelectedArrow(direction);
    if (direction === 'prev') {
      sliderRef.current?.slickPrev();
    } else {
      sliderRef.current?.slickNext();
    }
  };
  const handleBeforeChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  };

  // Calculate whether the arrows should be active/disabled
  const isPrevDisabled = currentSlide === home?.CHEF_PICKS?.length - 0;
  const isNextDisabled = currentSlide === home?.CHEF_PICKS?.length - 0;
  return <div className="populardish pt-100 pb-50" data-sentry-component="PopularDish" data-sentry-source-file="popularDish.jsx">
            <div className="populardish-txt width33">
                <h2>{t('looking_for_dish')}</h2>
                <p>{t('looking_for_dish_description')}</p>
                <div className="d-flex populardish-arrow">
                    <ArrowComponent onClick={() => handleArrowClick('prev')} disabled={isPrevDisabled} selected={selectedArrow === 'prev'} data-sentry-element="ArrowComponent" data-sentry-source-file="popularDish.jsx" />
                    <ArrowComponent className="arrow-button slick-next" onClick={() => handleArrowClick('next')} disabled={isNextDisabled} selected={selectedArrow === 'next'} data-sentry-element="ArrowComponent" data-sentry-source-file="popularDish.jsx" />
                </div>
            </div>
            <div className=" populardishSlider width66">
                <PopulardishSlider data={home?.CHEF_PICKS} handleBeforeChange={handleBeforeChange} sliderRef={sliderRef} data-sentry-element="PopulardishSlider" data-sentry-source-file="popularDish.jsx" />
            </div>
        </div>;
};
export default PopularDish;