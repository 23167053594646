import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
const settings = {
  className: 'center',
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  dots: false,
  variableWidth: true,
  autoplay: true,
  cssEase: 'linear',
  // nav: true,
  rows: 1,
  responsive: [{
    breakpoint: 769,
    settings: {
      centerMode: false,
      variableWidth: false
    }
  }]
};
function FullWidthSlider() {
  const {
    home
  } = useSelector(store => store.common);
  if (!home?.BANNER?.length) {
    return null;
  }
  const items = home?.BANNER?.map(item => {
    return <div className="item" key={item.web?.split('=').pop()}>
                <img src={item.web} alt={item.description} />
            </div>;
  });
  return <div className="slider-container" data-sentry-component="FullWidthSlider" data-sentry-source-file="FullwidthSlider.jsx">
            <Slider {...{
      ...settings,
      infinite: home?.BANNER?.length > 1,
      dots: false
    }} data-sentry-element="Slider" data-sentry-source-file="FullwidthSlider.jsx">
                {items}
            </Slider>
        </div>;
}
FullWidthSlider.propTypes = {
  data: PropTypes.array
};
export default FullWidthSlider;