import PropTypes from 'prop-types';

const ArrowComponent = ({ direction, className = '', onClick, disabled, selected }) => {
    const getArrowClass = () => {
        let className = 'arrow';
        if (disabled) className += ' disabled';
        if (selected) className += ' isActive';
        return className;
    };

    const handleArrowClick = () => {
        if (disabled) return;
        if (onClick) {
            onClick();
        }
    };

    return (
        <button
            className={`arrow-button ${className} ${getArrowClass()}`}
            onClick={handleArrowClick}
            aria-label={direction}
        ></button>
    );
};

export default ArrowComponent;

ArrowComponent.propTypes = {
    direction: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
};
