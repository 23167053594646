import { useAuth } from '@/hooks/useAuth';
import { getSliderSettings } from '@/utils';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
export default function TopSlider() {
  const {
    home
  } = useSelector(store => store.common);
  const {
    getLocaleContent
  } = useAuth();
  if (!home?.CATEGORY?.length) {
    return null;
  }
  const responsiveSettings = [{
    breakpoint: 1366,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 3,
      infinite: true,
      dots: false
    }
  }, {
    breakpoint: 991,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 2
    }
  }, {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1
    }
  }];
  const sliderSettings = getSliderSettings({
    data: home?.CATEGORY || [],
    responsive: responsiveSettings
  });
  const items = home?.CATEGORY?.map(category => <Link key={category.id} href={`/categories#${category.id}`}>
            <div className="items">
                <div className="item-bg">
                    <figure>
                        <img src={category.categoryImage} alt={getLocaleContent(category.name)} />
                    </figure>
                </div>
                <span>{getLocaleContent(category.name)}</span>
            </div>
        </Link>);
  return <Slider {...sliderSettings} data-sentry-element="Slider" data-sentry-component="TopSlider" data-sentry-source-file="topslider..jsx">{items}</Slider>;
}