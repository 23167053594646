import PropTypes from 'prop-types';
import Slider from 'react-slick';
function PopulardishSlider({
  data = [],
  handleBeforeChange,
  sliderRef
}) {
  const items = data?.map(item => {
    return <div className="item" key={item?.id}>
                <img src={item?.chefPickImages?.home?.web} alt="" />
            </div>;
  });
  const settings = {
    className: 'center',
    arrows: false,
    centerMode: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    rows: 1,
    nav: true,
    variableWidth: true,
    beforeChange: handleBeforeChange,
    responsive: [{
      breakpoint: 769,
      settings: {
        centerMode: false,
        variableWidth: false
      }
    }]
  };
  return <div className="PopulardishSlider-container" data-sentry-component="PopulardishSlider" data-sentry-source-file="PopulardishSlider.jsx">
            <Slider {...{
      ...settings,
      infinite: data?.length > 1,
      dots: false
    }} ref={sliderRef} data-sentry-element="Slider" data-sentry-source-file="PopulardishSlider.jsx">
                {items}
            </Slider>
        </div>;
}
PopulardishSlider.propTypes = {
  data: PropTypes.array,
  handleBeforeChange: PropTypes.func,
  sliderRef: PropTypes.any
};
export default PopulardishSlider;