import { localStorageKeys, modalTypeKey } from '@/configs/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import useUrlParams from '@/hooks/useUrlParams';
import { debugLog, getStoreHours, isFalsy } from '@/utils';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import nearbyClosed from '../../assets/images/nearby-closed.svg';
import notDelivery from '../../assets/images/not-delivery.svg';
const NotDelivery = () => {
  const t = useTranslations('Home');
  const {
    nearByStores,
    currentStore
  } = useSelector(store => store?.store) || {};
  const {
    loaders
  } = useSelector(store => store?.common);
  const {
    addMultipleParams
  } = useUrlParams();
  const {
    actions
  } = useLocalStorage(localStorageKeys.ACCOUNT_DATA);
  const deviceLocation = actions.getNestedValue(localStorageKeys.DEVICE_LOCATION) || {};
  const selectedAddress = actions.getNestedValue(localStorageKeys.SELECTED_ADDRESS) || {};
  if (!deviceLocation.isLocationAllowed || !selectedAddress?.mode) return null;
  let content = null;
  if (loaders.includes('getNearByStoresData') || loaders.includes('gestNearestStoreData')) return null;
  debugLog({
    message: 'NotDelivery',
    params: {
      nearByStores,
      currentStore,
      deviceLocation,
      selectedAddress
    }
  });
  const handleSwitchLocation = () => {
    addMultipleParams({
      [modalTypeKey]: 'locationSelector',
      mode: 'searchLocation'
    });
  };
  if (isFalsy(currentStore) || isFalsy(nearByStores) && nearByStores?.length) {
    content = <div className="not-delivery">
                <Image src={notDelivery} alt="" />
                <h4>
                    {t('noDeliveryAvailable')}
                    <span>{t('cannotDeliverToTheSelectedLocation')}</span>
                </h4>
                <button onClick={handleSwitchLocation}> {t('switchLocation')}</button>
            </div>;
  }
  if (!isFalsy(currentStore) && !currentStore.flag) {
    content = <div className="not-delivery">
                <Image src={nearbyClosed} alt="" />
                <h4>
                    {t('allNearbyStoresClosed')}
                    <span>{t('storeWillBeOpenAt', {
            openTime: getStoreHours(currentStore)?.open
          })}</span>
                </h4>
                <button onClick={handleSwitchLocation}> {t('switchLocation')}</button>
            </div>;
  }
  if (!content) return;
  return <div className="not-delivery-wrap" data-sentry-component="NotDelivery" data-sentry-source-file="NotDelivery.jsx">
            <div className="container">{content}</div>
        </div>;
};
export default NotDelivery;